import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { useStaticQuery, graphql } from "gatsby"
import WorkCard from "./WorkCard"
import HomeStyles from "../styles/components/HomeWorkSection.module.scss"
import Styles from "../styles/components/Expertise.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PlatformImages from "../components/expertisePlatform"

const ExpertiseWebsite = () => {
  const data = useStaticQuery(graphql`
    query technologyCaseStudySection {
      allCaseStudiesJson(
        limit: 2
        filter: { frontmatter: { workType: { eq: "website" } } }
      ) {
        edges {
          node {
            id
            challenge
            outcome
            frontmatter {
              slug
              workTitle
              featureImage
              companyName
              workType
            }
          }
        }
      }
    }
  `)
  const latestPosts = data.allCaseStudiesJson.edges.filter(
    caseStudy => caseStudy.node.frontmatter.workType == "website"
  )
  const introText =
    " Too many businesses do not know how to utilise their websites to achieve their business goals. They sometimes spend thousands of dollars only to find out it is not impacting the business, sometimes good websites are left for years without updates because the designer just put something up there.  "
  return (
    <div>
      <div
        className={`${Styles["background"]} ${
          Styles["section-container"]
        } container-padding`}
      >
        <div className={Styles["section-line--content-container"]}>
          <div className={Styles["section-line--title"]}>
            <h5>Approach</h5>
          </div>
          <div className={Styles["section-divider"]}>
            <div className={Styles["section-divider--text"]}>
              <h1 className={Styles["section-title--big"]}>
                Website Development
              </h1>
              <ScrollAnimation animateOnce animateIn="fadeIn">
                <div className={Styles["section-text--divider"]}>
                  <p>
                    <span>{introText}</span> Our goal is to work with you to
                    understand what your business goals are and how they align
                    with the users needs. We don't stop there we go a step
                    further to help you maintain and enhance the features when
                    appropriate, because your website strategy is not just a
                    destination but a journey
                    <br />
                    <br />
                    Once we understand the business, the audience, the problems
                    we can now craft a design direction that focuses on
                    delighting users and achieving business goals.
                  </p>
                </div>
                <br />
              </ScrollAnimation>

              <div className={Styles["services-subsection-container"]}>
                <h4>Key Deliverables</h4>
                <div className={Styles["services-subsection"]}>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Strategy</h3>
                    <p>Business Goals</p>
                    <p>Brand Analysis</p>
                    <p>Web Strategy</p>
                    <p>Brand Strategy</p>
                    <p>Campaign Strategy</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Design</h3>
                    <p>UI/UX Design</p>
                    <p>Brand Identity Design</p>
                    <p>Motion Design</p>
                    <p>Logo Design </p>
                    <p>Content Development</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Development</h3>
                    <p>CMS Development</p>
                    <p>JAMStack Development</p>
                    <p>Live Chats and Bots</p>
                    <p>Theme Creation</p>
                    <p>Front End Development</p>
                  </div>
                  <div className={Styles["services-subsection--service"]}>
                    <h3>Deployment</h3>
                    <p>CMS Trainings</p>
                    <p>A/B Testing</p>
                    <p>Surveys and Feedback</p>
                    <p>Email Setup</p>
                    <p>SEO and SEM</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div>
        <div className={Styles["process-section"]}>
          <div className={`${Styles["section-line--title"]} container-padding`}>
            <h5 className={Styles["section-line--dark"]}>Process</h5>
          </div>
          <div
            className={`${
              Styles["process-section-container"]
            } container-padding`}
          >
            <h3>Website Design Process</h3>
            <p>
              We approach design from a place of strategy, we realise that once
              there is a clear understanding of the brand we can make design
              decisions that are based on facts and not guesses.
            </p>

            <div className={Styles["wrap-collabsible"]}>
              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Consumer and Business Goals
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Our first stop is to understand the business and customer
                    goals, we ask questions that help you think through what the
                    core activities of the business are and what the goal for
                    the website should be.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Website Experience Strategy
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Crafting delightful experiences means thinking through how
                    the users would use the website, we experiment with
                    different ways they would be able to achieve their goals and
                    be delighted doing so.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Design Direction
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We would typically provide a few creative solutions based on
                    the core requirements for the website. Prioritizing the most
                    important needs for the user. Once a direction is chosen we
                    fine tune it to reveal a clearer picture of how the site
                    would look.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Content Strategy and Creation
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    During the previous phases we would conduct interviews with
                    major stakeholders and begin to piece the information into a
                    story. We use this interviews and discussions to piece out
                    content that would be used on the website.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Development and technology
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    Our engineers get to work, bringing the design to life.
                    Every detail is important and ensuring we deliver pixel
                    perfect quality is the goal here. We integrate third party
                    technologies that fit into the website and test them.
                  </p>
                </div>
              </div>

              <div for="collapsible" className={Styles["lbl-toggle"]}>
                <button className={Styles["collabsible-trigger"]}>
                  <span>+</span> Monitor and Grow
                </button>
                <div className={Styles["collabsible-content"]}>
                  <p>
                    We're ready to deploy and monitor the website, taking note
                    of how users are interacting with it and sending actionable
                    insights and data to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-padding">
          {/* the platform logos */}
          <PlatformImages website />
        </div>

        <div className="container-padding">
          {latestPosts.length !== 0 && (
            <div>
              <div className={Styles["section-line--title--dark"]}>
                <h5>Work</h5>
              </div>
              <div>
                <div className={Styles["case-study-area"]}>
                  <h2 className={Styles["featured-head-text"]}>
                    Featured Case Studies
                  </h2>
                  <div className={HomeStyles["section-portfolio"]}>
                    {latestPosts.map(({ node: post }) => (
                      <WorkCard
                        key={post.id}
                        companyName={post.frontmatter.companyName}
                        workTitle={post.frontmatter.workTitle}
                        imageSrc={post.frontmatter.featureImage}
                        slug={post.frontmatter.slug}
                      ></WorkCard>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ScrollAnimation animateOnce animateIn="bounceIn">
        <AniLink
          cover
          to="/expertise/apps"
          direction="right"
          bg="rgba(0, 0, 0)"
          duration={0.3}
          activeClassName="active"
          className={Styles["expertise-next-section-link"]}
        >
          <div className={Styles["expertise-next-section"]}>
            <p>Next </p>
            <h4>Application Development</h4>
          </div>
        </AniLink>
      </ScrollAnimation>
    </div>
  )
}

export default ExpertiseWebsite
