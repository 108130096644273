import React from "react"
import PageHero from "../../components/pagehero"
import Footer from "../../components/footer"
import WebsiteContent from "../../components/expertise-website"
import MobileHeader from "../../components/MobileHeader"

const ServicesWebsites = () => {
  return (
    <div>
      <MobileHeader></MobileHeader>
      <PageHero
        heading={["Website Design and Development"]}
        description="Designing interactive websites that create delightful visitor experinces and meet business goals"
        imgSrc="../../websites-bg.jpg"
        pageTitle="Websites"
        secondaryButtonTitle="Free Consultation"
        secondaryButtonUrl="contact"
      ></PageHero>
      <WebsiteContent />
      <Footer></Footer>
    </div>
  )
}

export default ServicesWebsites
